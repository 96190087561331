import { C2P_VISA_IDENTITY_TYPE } from '@/base/const/click2pay.const'
import { ToolsClass } from '@/base/class/tools.class'

export class VisaClick2payClass {
  constructor () {
    this.recognized = null
    this.idTokens = null
    this.profiles = null
    this.consumerPresent = null
    this.lastUsedCardTimestamp = null
    this.identityValidation = null
    this.maskedCard = null
    this.srcCorrelationId = null
    this.checkoutResult = null
    this.sdk = null
  }

  init ({
         initiatorId,
         dpaId,
         transactionId,
         transactionAmount,
         transactionCurrencyCode,
         locale,
         dpaPresentationName,
         dpaName
       }) {
    let vSrcAdapter = window.vAdapters.VisaSRCI
    // eslint-disable-next-line new-cap
    this.sdk = new vSrcAdapter()
    if (!this.sdk) {
      return Promise.reject(new Error('No Visa SDK instance!'))
    }
    const params = {
      srciTransactionId: transactionId,
      srcInitiatorId: initiatorId,
      srciDpaId: dpaId,
      dpaData: {
        dpaPresentationName: dpaPresentationName
      },
      dpaTransactionOptions: {
        dpaLocale: locale,
        merchantCountryCode: 'PL',
        transactionAmount: {
          transactionAmount: transactionAmount,
          transactionCurrencyCode: transactionCurrencyCode
        },
        customInputData: {
          checkoutOrchestrator: 'merchant'
        },
        paymentOptions: {
          dynamicDataType: 'CARD_APPLICATION_CRYPTOGRAM_LONG_FORM',
          dpaPanRequested: false
        },
        payloadTypeIndicator: 'FULL',
        dpaBillingPreference: 'NONE'
      }
    }
    if (ToolsClass.isDebugEnabled()) {
      console.log('visa sdk init params', JSON.stringify(params))
    }
    return this.sdk
      .init(params)
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  isRecognized () {
    try {
      return this.sdk
        .isRecognized()
        .then(response => {
          this.recognized = response.recognized
          if (response.recognized) {
            this.idTokens = response.idTokens
          }
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  getSrcProfile (idToken) {
    const params = {
      idTokens: [idToken]
    }
    return this.sdk
      .getSrcProfile(params)
      .then(response => {
        if (ToolsClass.isDebugEnabled()) {
          console.log('VISA getSrcProfile response', JSON.stringify(response))
        }
        this.profiles = response.profiles
        this.srcCorrelationId = response.srcCorrelationId
        if (ToolsClass.isDebugEnabled()) {
          console.log('VISA getSrcProfile srcCorrelationId', response.srcCorrelationId)
        }
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  identityLookup (email) {
    try {
      const params = {
        consumerIdentity:
          {
            identityProvider: 'SRC',
            identityValue: email,
            type: C2P_VISA_IDENTITY_TYPE.email
          }
      }
      return this.sdk
        .identityLookup(params)
        .then(response => {
          this.consumerPresent = response.consumerPresent
          this.lastUsedCardTimestamp = response.lastUsedCardTimestamp ? response.lastUsedCardTimestamp : null
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  initiateIdentityValidation () {
    try {
      return this.sdk
        .initiateIdentityValidation()
        .then(response => {
          this.identityValidation = response
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  completeIdentityValidation (validationData) {
    try {
      const params = {
        validationData: validationData
      }
      return this.sdk
        .completeIdentityValidation(params)
        .then(response => {
          this.idTokens = [response.idToken]
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  checkout (data) {
    try {
      const params = {}
      Object.keys(data).forEach(key => {
        if (key !== 'windowRef') {
          params[key] = data[key]
        }
      })
      if (ToolsClass.isDebugEnabled()) {
        console.log('visa sdk checkout params', JSON.stringify(params))
      }
      return this.sdk
        .checkout(data)
        .then(response => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('visa.click2pay.class.js checkout success response', response)
          }
          this.checkoutResult = response
          return Promise.resolve()
        })
        .catch(error => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('visa.click2pay.class.js checkout error response', error)
          }
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  unbindAppInstance () {
    try {
      return this.sdk
        .unbindAppInstance()
        .then(() => {
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  resetInstance () {
    this.recognized = null
    this.idTokens = null
    this.profiles = null
    this.consumerPresent = null
    this.lastUsedCardTimestamp = null
    this.identityValidation = null
    this.maskedCard = null
    this.srcCorrelationId = null
    this.checkoutResult = null
    return Promise.resolve()
  }
}

export const click2PayVisaClass = new VisaClick2payClass()
