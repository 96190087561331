import {
  C2P_MASTERCARD_IDENTITY_TYPE, CLICK2PAY_CARD_STATUS,
  CLICK2PAY_LOG_LEVEL,
  CLICK2PAY_ORGANIZATION,
  CLICK2PAY_VIEW
} from '@/base/const/click2pay.const'
import { ToolsClass } from '@/base/class/tools.class'
import { apiClass } from '@/base/class/api.class'
import { ENDPOINTS } from '@/base/const/endpoints.const'
import { CARD_ISSUERS } from '@/base/const/cards.const'

const state = {
  initData: null,
  loading: false,
  processing: false,
  isProcessAvailable: false,
  isProcessActive: false,
  identityValidationProvider: null,
  checkoutProvider: null,
  email: null,
  view: CLICK2PAY_VIEW.notRecognized,
  selectedCard: null,
  encryptedCard: null,
  consumer: {
    emailAddress: null,
    firstName: null,
    lastName: null,
    countryCode: 'PL',
    mobileNumber: {
      countryCode: null,
      phoneNumber: null
    }
  },
  rememberMe: false,
  transaction: {
    amount: null,
    currency: null
  }
}

const mutations = {
  SET_C2P_INIT_DATA (state, data) {
    state.initData = data
  },
  SET_C2P_LOADING (state, value) {
    state.loading = value
  },
  SET_C2P_PROCESSING (state, value) {
    state.processing = value
  },
  SET_C2P_IS_PROCESS_AVAILABLE (state, value) {
    state.isProcessAvailable = value
  },
  SET_C2P_IS_PROCESS_ACTIVE (state, value) {
    state.isProcessActive = value
  },
  SET_C2P_IDENTITY_VALIDATION_PROVIDER (state, value) {
    state.identityValidationProvider = value
  },
  SET_C2P_CHECKOUT_PROVIDER (state, value) {
    state.checkoutProvider = value
  },
  SET_C2P_EMAIL (state, value) {
    state.email = value
  },
  SET_C2P_VIEW (state, value) {
    state.view = value
  },
  SET_C2P_SELECTED_CARD (state, data) {
    state.selectedCard = data
  },
  SET_C2P_ENCRYPTED_CARD (state, data) {
    state.encryptedCard = data
  },
  SET_C2P_TRANSACTION_AMOUNT (state, value) {
    state.transaction.amount = value.toFixed(2)
  },
  SET_C2P_TRANSACTION_CURRENCY (state, value) {
    state.transaction.currency = value
  },
  SET_C2P_CONSUMER_EMAIL (state, value) {
    state.consumer.emailAddress = value
  },
  SET_C2P_CONSUMER_FIRSTNAME (state, value) {
    state.consumer.firstName = value
  },
  SET_C2P_CONSUMER_LASTNAME (state, value) {
    state.consumer.lastName = value
  },
  SET_C2P_CONSUMER_PHONE_COUNTRYCODE (state, value) {
    state.consumer.mobileNumber.countryCode = '' + value
  },
  SET_C2P_CONSUMER_PHONE_PHONENUMBER (state, value) {
    state.consumer.mobileNumber.phoneNumber = value
  },
  SET_C2P_REMEMBER_ME (state, value) {
    state.rememberMe = value
  },
  SET_C2P_PROCESS_RESET (state) {
    state.isProcessActive = false
    state.checkoutResult = null
    state.selectedCard = null
    state.rememberMe = false
  }
}

const actions = {
  setC2PInitData ({ state, commit, dispatch, rootState }, data) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('setC2PInitData', JSON.stringify(data))
    }
    if (!data) {
      return Promise.reject(new Error('No C2P init data!'))
    }
    const transaction = rootState.payment.paymentData.transaction
    const email = rootState.payment.paymentData.additionalData.customerEmail
    commit('SET_C2P_INIT_DATA', data)
    commit('SET_C2P_EMAIL', email)
    commit('SET_C2P_TRANSACTION_AMOUNT', transaction.payAmount)
    commit('SET_C2P_TRANSACTION_CURRENCY', transaction.payAmountCurrency)
    dispatch('loadC2PSDKScripts')
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  loadC2PSDKScripts ({ state, commit, dispatch }) {
    commit('SET_C2P_LOADING', true)
    const scriptsList = []
    if (state.initData.mastercard || (state.initData.srcInitiatorId && state.initData.srciDpaId)) {
      scriptsList.push(dispatch('loadC2PMastercardScript'))
    }
    if (state.initData.visa) {
      scriptsList.push(dispatch('loadC2PVisaScript'))
    }
    return Promise
      .all(scriptsList)
      .then(() => {
        return dispatch('initC2PSDK')
      })
      .catch(error => {
        commit('SET_C2P_LOADING', false)
        commit('SET_C2P_IS_PROCESS_AVAILABLE', false)
        return Promise.reject(error)
      })
  },
  initC2PSDK ({ state, commit, dispatch, getters }) {
    const sdkInitPromiseList = []
    if (getters.isC2PMastercardSDKLoaded) {
      sdkInitPromiseList.push(dispatch('getC2PMastercardInit', state.initData))
    }
    if (getters.isC2PVisaSDKLoaded) {
      sdkInitPromiseList.push(dispatch('getC2PVisaInit', state.initData))
    }
    if (ToolsClass.isDebugEnabled()) {
      console.log('sdkInitPromiseList', sdkInitPromiseList)
    }
    return Promise
      .all(sdkInitPromiseList)
      .then(() => {
        const sdkIsRecognizedPromiseList = []
        if (getters.isC2PMastercardSdkInitFinished) {
          sdkIsRecognizedPromiseList.push(dispatch('getC2PMastercardIsRecognized'))
        }
        if (getters.isC2PVisaSdkInitFinished) {
          sdkIsRecognizedPromiseList.push(dispatch('getC2PVisaIsRecognized'))
        }
        if (ToolsClass.isDebugEnabled()) {
          console.log('sdkIsRecognizedPromiseList', sdkIsRecognizedPromiseList)
        }
        return Promise
          .all(sdkIsRecognizedPromiseList)
          .then(() => {
            commit('SET_C2P_IS_PROCESS_AVAILABLE', true)
            // 1. oba nierozpoznane
            if (!getters.isC2PMastercardRecognizedByCookie && !getters.isC2PVisaRecognizedByCookie) {
              commit('SET_C2P_IS_PROCESS_ACTIVE', false)
              commit('SET_C2P_VIEW', CLICK2PAY_VIEW.notRecognized)
              commit('SET_C2P_LOADING', false)
              return Promise.resolve()
            }

            // 2. MC rozpoznany, Visa nierozpoznany
            if (getters.isC2PMastercardRecognizedByCookie && !getters.isC2PVisaRecognizedByCookie) {
              commit('SET_C2P_IS_PROCESS_ACTIVE', true)
              if (ToolsClass.isDebugEnabled()) {
                console.log('initC2PSDK - MC rozpoznany, Visa nie')
              }
              commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', CLICK2PAY_ORGANIZATION.mastercard)
              if (ToolsClass.isDebugEnabled()) {
                console.log('Mastercard zalogowany!')
              }
              if (!getters.isC2PVisaSdkInitFinished) {
                return Promise.resolve()
              }
              const idToken = getters.getC2PMastercardIdToken
              return dispatch('getC2PVisaGetSrcProfile', idToken)
                .then(() => {
                  return Promise.resolve()
                })
                .catch(() => {
                  return Promise.resolve()
                })
            }

            // 3. MC nierozpoznany, Visa rozpoznany
            if (!getters.isC2PMastercardRecognizedByCookie && getters.isC2PVisaRecognizedByCookie) {
              commit('SET_C2P_IS_PROCESS_ACTIVE', true)
              if (ToolsClass.isDebugEnabled()) {
                console.log('initC2PSDK - MC nierozpoznany, Visa rozpoznana')
              }
              commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', CLICK2PAY_ORGANIZATION.visa)
              if (ToolsClass.isDebugEnabled()) {
                console.log('Visa zalogowana!')
              }
              if (!getters.isC2PMastercardSdkInitFinished) {
                return Promise.resolve()
              }
              const idToken = getters.getC2PVisaIdToken
              return dispatch('getC2PMastercardGetSrcProfile', idToken)
                .then(() => {
                  return Promise.resolve()
                })
                .catch(() => {
                  return Promise.resolve()
                })
            }

            // 2. MC rozpoznany, Visa rozpoznany
            if (getters.isC2PMastercardRecognizedByCookie && getters.isC2PVisaRecognizedByCookie) {
              commit('SET_C2P_IS_PROCESS_ACTIVE', true)
              if (ToolsClass.isDebugEnabled()) {
                console.log('initC2PSDK - oba rozpoznane')
              }
              const mastercardLastUsedCardTimestamp = getters.getC2PMastercardLastUsedCardTimestamp
              const visaLastUsedCardTimestamp = getters.getC2PVisaLastUsedCardTimestamp
              if (ToolsClass.isDebugEnabled()) {
                console.log('visaLastUsedCardTimestamp', visaLastUsedCardTimestamp)
                console.log('mastercardLastUsedCardTimestamp', mastercardLastUsedCardTimestamp)
              }
              // a. nie mamy żadnej daty - wybieramy Mastercard, logowanie po emailu
              // b. mamy tylko datę z Mastercard - wybieramy Mastercard, logowanie po emailu
              // c. mamy obie daty i Mastercard jest młodszy - wybieramy Mastercard, logowanie po emailu
              if ((!mastercardLastUsedCardTimestamp && !visaLastUsedCardTimestamp) ||
                (mastercardLastUsedCardTimestamp && !visaLastUsedCardTimestamp) ||
                ((mastercardLastUsedCardTimestamp && visaLastUsedCardTimestamp) && (mastercardLastUsedCardTimestamp < visaLastUsedCardTimestamp))) {
                commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', CLICK2PAY_ORGANIZATION.mastercard)
                if (ToolsClass.isDebugEnabled()) {
                  console.log('Mastercard zalogowany!')
                }
                const idToken = getters.getC2PMastercardIdToken
                return dispatch('getC2PVisaGetSrcProfile', idToken)
                  .then(() => {
                    return Promise.resolve()
                  })
                  .catch(() => {
                    return Promise.resolve()
                  })
              } else {
                // w pozostałych wypadkach wybieramy Vise
                commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', CLICK2PAY_ORGANIZATION.visa)
                if (ToolsClass.isDebugEnabled()) {
                  console.log('Visa zalogowana!')
                }
                const idToken = getters.getC2PVisaIdToken
                return dispatch('getC2PMastercardGetSrcProfile', idToken)
                  .then(() => {
                    return Promise.resolve()
                  })
                  .catch(() => {
                    return Promise.resolve()
                  })
              }
            }
          })
          .then(() => {
            if (!state.identityValidationProvider) {
              commit('SET_C2P_LOADING', false)
              return Promise.resolve()
            }
            const cardList = getters.getC2PCards
            if (!cardList.length) {
              commit('SET_C2P_VIEW', CLICK2PAY_VIEW.newCard)
              commit('SET_C2P_LOADING', false)
              return Promise.resolve()
            }
            dispatch('setC2PSelectedCard', cardList[0])
            commit('SET_C2P_VIEW', CLICK2PAY_VIEW.cardSelected)
            commit('SET_C2P_LOADING', false)
            return Promise.resolve()
          })
      })
      .catch(error => {
        commit('SET_C2P_LOADING', false)
        commit('SET_C2P_IS_PROCESS_AVAILABLE', false)
        return Promise.reject(error)
      })
  },
  getC2PIdentityLookup ({ state, commit, dispatch, getters }) {
    const identityLookupPromiseList = []
    dispatch('resetC2PData')
    if (getters.isC2PMastercardSdkInitFinished) {
      identityLookupPromiseList.push(dispatch('resetC2PMastercardData'))
      identityLookupPromiseList.push(dispatch('getC2PMastercardIdentityLookup', state.email))
    }
    if (getters.isC2PVisaSdkInitFinished) {
      identityLookupPromiseList.push(dispatch('resetC2PVisaData'))
      identityLookupPromiseList.push(dispatch('getC2PVisaIdentityLookup', state.email))
    }
    commit('SET_C2P_LOADING', true)
    commit('SET_C2P_IS_PROCESS_ACTIVE', true)
    return Promise
      .allSettled(identityLookupPromiseList)
      .then(() => {
        if (ToolsClass.isDebugEnabled()) {
          console.log('C2P Mastercard recognized by email', getters.isC2PMastercardRecognizedByEmail)
          console.log('C2P Visa recognized by email', getters.isC2PVisaRecognizedByEmail)
        }
        // 1. oba nierozpoznane
        if (!getters.isC2PMastercardRecognizedByEmail && !getters.isC2PVisaRecognizedByEmail) {
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PIdentityLookup - oba nierozpoznane')
          }
          commit('SET_C2P_IS_PROCESS_ACTIVE', false)
          commit('SET_C2P_VIEW', CLICK2PAY_VIEW.notRecognized)
          commit('SET_C2P_LOADING', false)
          return Promise.resolve()
        }

        // 2. MC rozpoznany, Visa nie
        if (getters.isC2PMastercardRecognizedByEmail && !getters.isC2PVisaRecognizedByEmail) {
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PIdentityLookup - MC rozpoznany, Visa nie')
          }
          commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', CLICK2PAY_ORGANIZATION.mastercard)
          return dispatch('getC2PMastercardInitiateIdentityValidation', C2P_MASTERCARD_IDENTITY_TYPE.email)
            .then(() => {
              commit('SET_C2P_VIEW', CLICK2PAY_VIEW.codeForm)
              commit('SET_C2P_LOADING', false)
              return Promise.resolve()
            })
            .catch(error => {
              return Promise.reject(error)
            })
        }

        // 3. MC nierozpoznany, Visa rozpoznana
        if (!getters.isC2PMastercardRecognizedByEmail && getters.isC2PVisaRecognizedByEmail) {
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PIdentityLookup - MC nierozpoznany, Visa rozpoznana')
          }
          commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', CLICK2PAY_ORGANIZATION.visa)
          return dispatch('getC2PVisaInitiateIdentityValidation')
            .then(() => {
              commit('SET_C2P_VIEW', CLICK2PAY_VIEW.codeForm)
              commit('SET_C2P_LOADING', false)
              return Promise.resolve()
            })
            .catch(error => {
              return Promise.reject(error)
            })
        }

        // 4. oba rozpoznane
        if (getters.isC2PMastercardRecognizedByEmail && getters.isC2PVisaRecognizedByEmail) {
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PIdentityLookup - oba rozpoznane')
          }
          const mastercardLastUsedCardTimestamp = getters.getC2PMastercardLastUsedCardTimestamp
          const visaLastUsedCardTimestamp = getters.getC2PVisaLastUsedCardTimestamp
          if (ToolsClass.isDebugEnabled()) {
            console.log('visaLastUsedCardTimestamp', visaLastUsedCardTimestamp)
            console.log('mastercardLastUsedCardTimestamp', mastercardLastUsedCardTimestamp)
          }

          // a. nie mamy żadnej daty - wybieramy Mastercard, logowanie po emailu
          // b. mamy tylko datę z Mastercard - wybieramy Mastercard, logowanie po emailu
          // c. mamy obie daty i Mastercard jest młodszy - wybieramy Mastercard, logowanie po emailu
          if ((!mastercardLastUsedCardTimestamp && !visaLastUsedCardTimestamp) ||
            (mastercardLastUsedCardTimestamp && !visaLastUsedCardTimestamp) ||
            ((mastercardLastUsedCardTimestamp && visaLastUsedCardTimestamp) && (mastercardLastUsedCardTimestamp > visaLastUsedCardTimestamp))) {
            commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', CLICK2PAY_ORGANIZATION.mastercard)
            return dispatch('getC2PMastercardInitiateIdentityValidation', C2P_MASTERCARD_IDENTITY_TYPE.email)
              .then(() => {
                commit('SET_C2P_VIEW', CLICK2PAY_VIEW.codeForm)
                commit('SET_C2P_LOADING', false)
                return Promise.resolve()
              })
              .catch(error => {
                return Promise.reject(error)
              })
          } else {
            // w pozostałych wypadkach wybieramy Vise
            commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', CLICK2PAY_ORGANIZATION.visa)
            return dispatch('getC2PVisaInitiateIdentityValidation')
              .then(() => {
                commit('SET_C2P_VIEW', CLICK2PAY_VIEW.codeForm)
                commit('SET_C2P_LOADING', false)
                return Promise.resolve()
              })
              .catch(error => {
                return Promise.reject(error)
              })
          }
        }
      })
      .catch(error => {
        commit('SET_C2P_LOADING', false)
        if (ToolsClass.isDebugEnabled()) {
          console.log('getC2PIdentityLookup error', error)
        }
        return Promise.reject(error)
      })
  },
  getC2PInitiateIdentityValidation ({ dispatch }, validationChannel) {
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.mastercard) {
      return dispatch('getC2PMastercardInitiateIdentityValidation', validationChannel)
    }
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.visa) {
      return dispatch('getC2PVisaInitiateIdentityValidation')
    }
  },
  getC2PCompleteIdentityValidation ({ commit, dispatch, getters }, validationData) {
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.mastercard) {
      const data = {
        validationData: validationData,
        rememberMe: state.rememberMe
      }
      return dispatch('getC2PMastercardCompleteIdentityValidation', data)
        .then(() => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('Mastercard zalogowany!')
          }
          commit('SET_C2P_IS_PROCESS_ACTIVE', true)
          if (!getters.isC2PVisaProcessAvailable || !getters.isC2PVisaRecognizedByEmail) {
            return Promise.resolve()
          }
          const idToken = getters.getC2PMastercardIdToken
          return dispatch('getC2PVisaGetSrcProfile', idToken)
            .then(() => {
              return Promise.resolve()
            })
            .catch(() => {
              return Promise.resolve()
            })
        })
        .then(() => {
          commit('SET_C2P_LOADING', false)
          return Promise.resolve()
        })
        .catch(error => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PCompleteIdentityValidation error', error)
          }
          return Promise.reject(error)
        })
    }
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.visa) {
      return dispatch('getC2PVisaCompleteIdentityValidation', validationData)
        .then(() => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('Visa zalogowana!')
          }
          commit('SET_C2P_IS_PROCESS_ACTIVE', true)
          if (!getters.isC2PMastercardProcessAvailable || !getters.isC2PMastercardRecognizedByEmail) {
            return Promise.resolve()
          }
          const idToken = getters.getC2PVisaIdToken
          return dispatch('getC2PMastercardGetSrcProfile', idToken)
            .then(() => {
              return Promise.resolve()
            })
            .catch(() => {
              return Promise.resolve()
            })
        })
        .then(() => {
          commit('SET_C2P_LOADING', false)
          return Promise.resolve()
        })
        .catch(error => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PCompleteIdentityValidation error', error)
          }
          return Promise.reject(error)
        })
    }
  },
  setC2PSelectedCard ({ commit, getters }, cardData) {
    if (!cardData) {
      commit('SET_C2P_SELECTED_CARD', null)
      commit('SET_C2P_CHECKOUT_PROVIDER', null)
      return Promise.resolve()
    }
    const cardsIdMcList = getters.getC2PMastercardCardsIds
    const cardsIdVisaList = getters.getC2PVisaCardsIds
    const srcDigitalCardId = cardData.srcDigitalCardId
    commit('SET_C2P_SELECTED_CARD', cardData)
    commit('SET_C2P_ENCRYPTED_CARD', null)
    if (cardsIdMcList.includes(srcDigitalCardId)) {
      commit('SET_C2P_CHECKOUT_PROVIDER', CARD_ISSUERS.mastercard)
    }
    if (cardsIdVisaList.includes(srcDigitalCardId)) {
      commit('SET_C2P_CHECKOUT_PROVIDER', CARD_ISSUERS.visa)
    }
    return Promise.resolve()
  },
  getC2PCheckout ({ commit, state, dispatch, getters }, locale) {
    const srcDigitalCardId = state.selectedCard ? state.selectedCard.srcDigitalCardId : null
    const encryptedCard = state.encryptedCard ? state.encryptedCard : null
    const data = {
      srcDigitalCardId: srcDigitalCardId,
      encryptedCard: encryptedCard,
      locale: locale,
      rememberMe: state.rememberMe,
      consumer: state.consumer
    }
    if (state.checkoutProvider === CARD_ISSUERS.mastercard) {
      return dispatch('getC2PMastercardCheckout', data)
        .then(() => {
          const mastercardCheckoutResult = getters.getC2PMastercardCheckoutResult
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PCheckout mastercardCheckoutResult', JSON.stringify(mastercardCheckoutResult))
          }
          return Promise.resolve({ checkoutResult: mastercardCheckoutResult })
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
    if (state.checkoutProvider === CARD_ISSUERS.visa) {
      return dispatch('getC2PVisaCheckout', data)
        .then(() => {
          const visaCheckoutResult = getters.getC2PVisaCheckoutResult
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PCheckout visaCheckoutResult', JSON.stringify(visaCheckoutResult))
          }
          return Promise.resolve({ checkoutResult: visaCheckoutResult })
        })
        .catch(error => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('getC2PCheckout error', error)
          }
          return Promise.reject(error)
        })
    }
    return Promise.reject(new Error('srcDigitalCardId not found on card list!'))
  },
  getC2PUnbindAppInstance ({ dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PUnbindAppInstance')
    }
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.mastercard) {
      return dispatch('getC2PMastercardUnbindAppInstance')
        .then(() => {
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.visa) {
      return dispatch('getC2PVisaUnbindAppInstance')
        .then(() => {
          return Promise.resolve()
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
    return Promise.resolve()
  },
  resetC2PData ({ commit }) {
    commit('SET_C2P_REMEMBER_ME', false)
    commit('SET_C2P_SELECTED_CARD', null)
    commit('SET_C2P_ENCRYPTED_CARD', null)
    commit('SET_C2P_IDENTITY_VALIDATION_PROVIDER', null)
    commit('SET_C2P_CHECKOUT_PROVIDER', null)
  },
  logC2PTransaction ({ rootState }, {
    context = '',
    message = '',
    logLevel = CLICK2PAY_LOG_LEVEL.info,
    isFatal = false,
    organization = CLICK2PAY_ORGANIZATION.mastercard
  }) {
    const data = {
      idTransRemote: rootState.payment?.paymentData?.transRemoteID,
      loglevel: logLevel,
      message: JSON.stringify(message),
      browser: window.navigator.userAgent,
      context: context,
      fatal: isFatal,
      organization: organization
    }
    if (!ToolsClass.isProduction()) {
      console.info(JSON.stringify(data))
      return Promise.resolve()
    }
    return apiClass.postCards(ENDPOINTS.c2p.log, data)
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

const getters = {
  getC2PIdentityValidation (state, getters) {
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.mastercard) {
      return getters.getC2PMastercardIdentityValidation
    }
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.visa) {
      return getters.getC2PVisaIdentityValidation
    }
  },
  getC2PProfile (state, getters) {
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.mastercard) {
      return getters.getC2PMastercardProfile
    }
    if (state.identityValidationProvider === CLICK2PAY_ORGANIZATION.visa) {
      return getters.getC2PVisaProfile
    }
  },
  getC2PCards (state, getters) {
    const cardStatuses = [
      CLICK2PAY_CARD_STATUS.active,
      CLICK2PAY_CARD_STATUS.pending
    ]
    let cardList = []
    const cardMastercardList = getters.getC2PMastercardCards
    const cardVisaList = getters.getC2PVisaCards
    if (cardMastercardList.length) {
      let activeCards = cardMastercardList.filter(card => cardStatuses.includes(card.digitalCardData.status))
      if (activeCards.length) {
        cardList = cardList.concat(activeCards)
      }
    }
    if (cardVisaList.length) {
      let activeCards = cardVisaList.filter(card => cardStatuses.includes(card.digitalCardData.status))
      if (activeCards.length) {
        cardList = cardList.concat(activeCards)
      }
    }
    if (cardList.length) {
      cardList = ToolsClass.sortArrayByObjectProperty(cardList, 'dateOfCardLastUsed').reverse()
    }
    return cardList
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
