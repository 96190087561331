<template>
  <form ref="form-usercode" @submit.prevent="submit" v-form:form novalidate>
    <click-to-pay-profile />
    <div class="form-row">
      <h3><strong>{{ $t('paywall.form.click2pay.codeForm.description') }}</strong></h3>
      <p v-if="isProviderMastercard">
        <span v-if="isActiveValidationChannelEmail()">{{ $t('paywall.form.click2pay.codeForm.codeSentEmail', {email: getValidationChannel()}) }}</span>
        <span v-if="isActiveValidationChannelPhone()">{{ $t('paywall.form.click2pay.codeForm.codeSentPhone', {phone: getValidationChannel()}) }}</span>
      </p>
      <p v-if="isProviderVisa">
        <span>{{ $t('paywall.form.click2pay.codeForm.codeSent', {validationChannel: getValidationChannel()}) }}</span>
      </p>
    </div>
    <div class="form-row input-text-field" :class="{'is-error': isErrorForField('code')}">
      <label for="code">{{ $t('paywall.form.click2pay.codeForm.codeLabel') }}</label>
      <p role="alert" tabindex="0"
         v-if="isErrorForField('code')"
         class="error"
         :aria-label="getFieldErrorAriaLabel('code')">
        <span aria-hidden="true">{{ getFieldErrorText('code') }}</span>
      </p>
      <div class="input-text-wrapper" :class="{'is-error': isErrorForField('code')}">
        <input
          type="text"
          ref="code"
          name="code"
          id="code"
          :placeholder="$t('paywall.form.click2pay.codeForm.codePlaceholder')"
          required
          v-model.trim="formData.code" />
        <span class="input-flow-label">&nbsp;{{ $t('paywall.form.click2pay.codeForm.codeLabelFlow') }}&nbsp;</span>
        <span class="input-flow-mark"></span>
      </div>
      <p class="c2p-action" tabindex="0" v-if="isProviderVisa">
        <button type="button" @click="resendCode" class="btn btn-plain">{{ $t('paywall.form.click2pay.codeForm.codeSendLabel') }}</button>
      </p>
      <p class="c2p-action" tabindex="0" v-if="isProviderMastercard">
        {{ $t('paywall.form.click2pay.codeForm.codeSendLabel') }}
        <span class="sr-only"> {{ $t('paywall.form.click2pay.codeForm.codeSendAriaLabel') }}</span>
        <button type="button" @click="resendCodeSms" class="btn btn-plain">{{ $t('paywall.form.click2pay.codeForm.buttonSendNewSms') }}</button> | <span class="sr-only"> {{ $t('paywall.form.click2pay.codeForm.codeSendAriaLabel2') }}</span> <button type="button" @click="resendCodeEmail" class="btn btn-plain">{{ $t('paywall.form.click2pay.codeForm.buttonSendNewEmail') }}</button>
      </p>
    </div>
    <div class="agreements">
      <div class="form-row">
        <input type="checkbox"
               id="trusted"
               name="trusted"
               @keydown.13.prevent
               v-model="formData.trusted" />
        <label for="trusted">
        <span v-html="$t('paywall.form.click2pay.codeForm.trustedLabel')"></span>
        </label>
      </div>
    </div>
    <div class="submit-wrapper">
      <div class="c2p-submit">
        <button type="button" @click="goToClick2PayNewCard" class="btn btn-plain">{{ $t('paywall.form.click2pay.codeForm.buttonChangeCard') }}</button>
        <button type="submit" class="btn" :class="{'inactive': isFormInvalid()}" :disabled="processing">
          <span>{{ $t('paywall.form.click2pay.codeForm.buttonSubmit') }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import ClickToPayProfile from '@/base/components/Payment/Paywall/ClickToPay/Profile.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  CLICK2PAY_VIEW,
  C2P_MASTERCARD_IDENTITY_TYPE,
  CLICK2PAY_MC_ERROR_MESSAGES,
  CLICK2PAY_ORGANIZATION, C2P_VISA_IDENTITY_TYPE, CLICK2PAY_VISA_ERROR_REASON
} from '@/base/const/click2pay.const'
import formMixin from '@/base/mixins/form.mixin'
import { MESSAGES } from '@/base/const/texts.const'
import { ToolsClass } from '@/base/class/tools.class'
const maxBadCodeCounter = 3

export default {
  name: 'ClickToPayCodeForm',
  mixins: [formMixin],
  components: { ClickToPayProfile },
  data () {
    return {
      badCodeCounter: 0,
      formData: {
        code: null,
        trusted: false
      }
    }
  },
  computed: {
    ...mapGetters({
      identityValidation: 'getC2PIdentityValidation',
      cardList: 'getC2PCards'
    }),
    ...mapState({
      processing: state => state.c2p.processing,
      identityValidationProvider: state => state.c2p.identityValidationProvider
    }),
    isProviderMastercard: function () {
      return this.identityValidationProvider === CLICK2PAY_ORGANIZATION.mastercard
    },
    isProviderVisa: function () {
      return this.identityValidationProvider === CLICK2PAY_ORGANIZATION.visa
    },
    activeIdentityType: function () {
      if (!this.identityValidation) {
        return
      }
      if (this.isProviderMastercard) {
        return this.identityValidation.supportedValidationChannels.find(channel => channel.maskedValidationChannel === this.identityValidation.maskedValidationChannel).identityType
      }
      return C2P_VISA_IDENTITY_TYPE.email
    }
  },
  methods: {
    ...mapMutations([
      'SET_C2P_PROCESSING',
      'SET_C2P_VIEW',
      'SET_C2P_REMEMBER_ME',
      'SET_C2P_PROCESS_RESET'
    ]),
    ...mapActions([
      'getC2PInitiateIdentityValidation',
      'getC2PCompleteIdentityValidation',
      'setC2PSelectedCard',
      'addMessageSuccess',
      'addMessageError'
    ]),
    showBadCodeCounterExceededMessage () {
      const message = {
        text: MESSAGES().errors.codeCounterExceeded,
        options: {
          time: 6000
        }
      }
      this.addMessageError(message)
    },
    isBadCodeCounterExceeded () {
      return this.badCodeCounter >= maxBadCodeCounter
    },
    getValidationChannel () {
      return this.identityValidation?.maskedValidationChannel
    },
    isActiveValidationChannelEmail () {
      return this.activeIdentityType === C2P_MASTERCARD_IDENTITY_TYPE.email
    },
    isActiveValidationChannelPhone () {
      return this.activeIdentityType === C2P_MASTERCARD_IDENTITY_TYPE.phone
    },
    isFormInvalid () {
      if (this.form) {
        return this.form.invalid
      }
      return false
    },
    goToClick2PayNewCard () {
      // this.SET_C2P_PROCESS_RESET()
      this.SET_C2P_VIEW(CLICK2PAY_VIEW.newCard)
    },
    resendCodeSms () {
      this.resendCode(C2P_MASTERCARD_IDENTITY_TYPE.phone)
    },
    resendCodeEmail () {
      this.resendCode(C2P_MASTERCARD_IDENTITY_TYPE.email)
    },
    resendCode (validationChannel = null) {
      this.SET_C2P_PROCESSING(true)
      this.getC2PInitiateIdentityValidation(validationChannel)
        .catch(() => {
          const message = {
            text: MESSAGES().errors.codeNotSent,
            options: {
              time: 2000
            }
          }
          this.addMessageError(message)
        })
        .finally(() => {
          const message = {
            text: MESSAGES().success.codeSent,
            options: {
              time: 2000
            }
          }
          this.addMessageSuccess(message)
          this.SET_C2P_PROCESSING(false)
          this.$refs['code'].focus()
        })
    },
    submit () {
      if (this.isFormInvalid()) {
        return false
      }

      if (this.isBadCodeCounterExceeded()) {
        this.showBadCodeCounterExceededMessage()
        return false
      }

      this.SET_C2P_REMEMBER_ME(this.formData.trusted)
      this.SET_C2P_PROCESSING(true)
      this.getC2PCompleteIdentityValidation(this.formData.code)
        .then(() => {
          setTimeout(() => {
            if (!this.cardList.length) {
              this.goToClick2PayNewCard()
              return
            }
            this.setC2PSelectedCard(this.cardList[0]).then(() => {
              this.SET_C2P_VIEW(CLICK2PAY_VIEW.cardSelected)
            })
          }, 1000)
        })
        .catch(error => {
          if (this.isProviderMastercard) {
            if (ToolsClass.isDebugEnabled()) {
              console.log('getC2PCompleteIdentityValidation MC error', error.toString())
            }
            switch (error.toString()) {
              // konto zablokowane
              case CLICK2PAY_MC_ERROR_MESSAGES.codeAccountLocked:
              case CLICK2PAY_MC_ERROR_MESSAGES.accountInaccessible:
                this.addErrorToField('code', 'codeAccountLocked')
                break
              // kod nieprawidłowy
              case CLICK2PAY_MC_ERROR_MESSAGES.codeNotValid:
                this.badCodeCounter++
                this.addErrorToField('code', 'codeNotValid')
                this.SET_C2P_PROCESSING(false)
                if (this.isBadCodeCounterExceeded()) {
                  this.showBadCodeCounterExceededMessage()
                }
                break
            }
          }
          if (this.isProviderVisa) {
            if (ToolsClass.isDebugEnabled()) {
              console.log('getC2PCompleteIdentityValidation VISA error', error)
            }
            switch (error?.error?.reason) {
              // konto zablokowane
              case CLICK2PAY_VISA_ERROR_REASON.acctInaccessible:
                this.addErrorToField('code', 'codeAccountLocked')
                break
              // nieznany błąd
              case CLICK2PAY_VISA_ERROR_REASON.unknownError:
                this.addErrorToField('code', 'unknownError')
                break
              // kod nieprawidłowy
              case CLICK2PAY_VISA_ERROR_REASON.codeInvalid:
                this.badCodeCounter++
                this.addErrorToField('code', 'codeNotValid')
                this.SET_C2P_PROCESSING(false)
                if (this.isBadCodeCounterExceeded()) {
                  this.showBadCodeCounterExceededMessage()
                }
                break
            }
          }
        })
        .finally(() => {
          this.SET_C2P_PROCESSING(false)
        })
    }
  },
  mounted () {
    this.SET_C2P_PROCESSING(false)
    if (this.$refs['code']) {
      this.$refs['code'].focus()
    }
  }
}
</script>

<style lang="scss">
@import "./src/base/assets/scss/components/PaywallForm.scss";
.c2p-action {
  font-size: 12px;
  button.btn.btn-plain {
    font-size: 12px;
    text-transform: unset;
  }
}
.submit-wrapper {
  padding-top: 10px;
  .c2p-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button.btn.btn-plain {
      font-size: 12px;
      text-transform: unset;
    }
  }
}
</style>
