import { ToolsClass } from '@/base/class/tools.class'
import { CLICK2PAY_LOG_LEVEL, CLICK2PAY_ORGANIZATION } from '@/base/const/click2pay.const'
import { click2PayMastercardClass } from '@/base/class/mastercard.click2pay.class'
import { LANGUAGES_CODE_LIST } from '@/base/const/lang.const'

const state = {
  loading: false,
  processing: false,
  isSdkLoaded: false,
  isSdkInitFinished: false,
  isProcessAvailable: false,
  isProcessActive: false,
  isRecognizedByCookie: null,
  isRecognizedByEmail: null,
  identityValidation: null,
  profiles: null,
  idToken: null,
  srcCorrelationId: null,
  lastUsedCardTimestamp: null,
  checkoutResult: null
}

const mutations = {
  SET_C2P_MASTERCARD_LOADING (state, value) {
    state.loading = value
  },
  SET_C2P_MASTERCARD_PROCESSING (state, value) {
    state.processing = value
  },
  SET_C2P_MASTERCARD_IS_SDK_LOADED (state, value) {
    state.isSdkLoaded = value
  },
  SET_C2P_MASTERCARD_IS_SDK_INIT_FINISHED (state, value) {
    state.isSdkInitFinished = value
  },
  SET_C2P_MASTERCARD_IS_PROCESS_AVAILABLE (state, value) {
    state.isProcessAvailable = value
  },
  SET_C2P_MASTERCARD_IS_PROCESS_ACTIVE (state, value) {
    state.isProcessActive = value
  },
  SET_C2P_MASTERCARD_IS_RECOGNIZED_BY_COOKIE (state, value) {
    state.isRecognizedByCookie = value
  },
  SET_C2P_MASTERCARD_IS_RECOGNIZED_BY_EMAIL (state, value) {
    state.isRecognizedByEmail = value
  },
  SET_C2P_MASTERCARD_IDENTITY_VALIDATION (state, data) {
    state.identityValidation = data
  },
  SET_C2P_MASTERCARD_PROFILES (state, data) {
    state.profiles = data
  },
  SET_C2P_MASTERCARD_ID_TOKEN (state, value) {
    state.idToken = value
  },
  SET_C2P_MASTERCARD_SRC_CORRELATION_ID (state, value) {
    state.srcCorrelationId = value
  },
  SET_C2P_MASTERCARD_LAST_USED_CARD_TIMESTAMP (state, value) {
    state.lastUsedCardTimestamp = value
  },
  SET_C2P_MASTERCARD_CHECKOUT_RESULT (state, value) {
    state.checkoutResult = value
  }
}

const actions = {
  loadC2PMastercardScript ({ commit, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('loadC2PMastercardScript')
    }
    return new Promise((resolve, reject) => {
      commit('SET_C2P_MASTERCARD_LOADING', true)
      const script = document.createElement('script')
      script.src = process.env.VUE_APP_MASTERCARD_SDK
      script.async = true
      script.addEventListener('load', () => {
        commit('SET_C2P_MASTERCARD_LOADING', false)
        commit('SET_C2P_MASTERCARD_IS_SDK_LOADED', true)
        dispatch('logC2PTransaction', {
          context: 'C2P Mastercard SDK load',
          message: 'C2P Mastercard SDK loaded',
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        if (ToolsClass.isDebugEnabled()) {
          console.log('C2P Mastercard SDK loaded')
        }
        resolve(void 0)
      })
      script.addEventListener('error', (error) => {
        commit('SET_C2P_MASTERCARD_LOADING', false)
        commit('SET_C2P_MASTERCARD_IS_SDK_LOADED', false)
        dispatch('logC2PTransaction', {
          context: 'C2P Mastercard SDK load',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          isFatal: true,
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        reject(error)
      })
      document.head.appendChild(script)
    })
  },
  getC2PMastercardInit ({ commit, state, dispatch, rootState }, initData) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PMastercardInit', initData)
    }
    commit('SET_C2P_MASTERCARD_LOADING', true)
    const srcInitiatorId = initData.mastercard.srcInitiatorId ? initData.mastercard.srcInitiatorId : initData.srcInitiatorId
    const srciDpaId = initData.mastercard.srciDpaId ? initData.mastercard.srciDpaId : initData.srciDpaId
    const transaction = rootState.payment.paymentData.transaction
    const language = rootState.lang.current
    const dpaPresentationName = transaction.receiverNameForFront.length > 60 ? transaction.receiverNameForFront.substring(0, 60) : transaction.receiverNameForFront
    const params = {
      initiatorId: srcInitiatorId,
      dpaId: srciDpaId,
      transactionId: initData.srciTransactionId,
      transactionAmount: transaction.payAmount,
      transactionCurrencyCode: transaction.payAmountCurrency,
      locale: LANGUAGES_CODE_LIST[language],
      dpaPresentationName: dpaPresentationName,
      dpaName: dpaPresentationName
    }
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PMastercardInit params', params)
    }

    const abortController = new AbortController()
    const abortSignal = abortController.signal
    let timeout = null

    timeout = setTimeout(() => {
      abortController.abort()
    }, 10000)

    return click2PayMastercardClass.init(params)
      .then(response => {
        commit('SET_C2P_MASTERCARD_IS_SDK_INIT_FINISHED', true)
        dispatch('logC2PTransaction', {
          context: 'C2P Mastercard init',
          message: 'C2P getC2PMastercardInit success',
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })

        if (abortSignal.aborted) {
          console.log('getC2PMastercardInit aborted!')
          commit('SET_C2P_MASTERCARD_LOADING', false)
          commit('SET_C2P_MASTERCARD_IS_SDK_INIT_FINISHED', false)
          return Promise.resolve()
        }
        clearTimeout(timeout)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_C2P_MASTERCARD_LOADING', false)
        commit('SET_C2P_MASTERCARD_IS_SDK_INIT_FINISHED', false)
        dispatch('logC2PTransaction', {
          context: 'C2P Mastercard init',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          isFatal: true,
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        return Promise.resolve()
      })
  },
  getC2PMastercardIsRecognized ({ commit, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PMastercardIsRecognized')
    }
    commit('SET_C2P_MASTERCARD_LOADING', true)
    return click2PayMastercardClass.isRecognized()
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P isRecognized',
          message: 'C2P getC2PMastercardIsRecognized success',
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        commit('SET_C2P_MASTERCARD_IS_RECOGNIZED_BY_COOKIE', click2PayMastercardClass.recognized)
        commit('SET_C2P_MASTERCARD_IS_PROCESS_AVAILABLE', true)
        if (click2PayMastercardClass.recognized) {
          commit('SET_C2P_MASTERCARD_IS_RECOGNIZED_BY_EMAIL', null)
          commit('SET_C2P_MASTERCARD_ID_TOKEN', click2PayMastercardClass.idTokens[0])
          return dispatch('getC2PMastercardGetSrcProfile', click2PayMastercardClass.idTokens[0])
        } else {
          commit('SET_C2P_MASTERCARD_LOADING', false)
        }
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_C2P_MASTERCARD_LOADING', false)
        commit('SET_C2P_MASTERCARD_IS_PROCESS_AVAILABLE', false)
        dispatch('logC2PTransaction', {
          context: 'C2P isRecognized',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        return Promise.reject(error)
      })
  },
  getC2PMastercardGetSrcProfile ({ commit, dispatch }, idToken) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PMastercardGetSrcProfile')
    }
    commit('SET_C2P_MASTERCARD_LOADING', true)
    return click2PayMastercardClass.getSrcProfile(idToken)
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P getSrcProfile',
          message: 'C2P getC2PMastercardGetSrcProfile success',
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        commit('SET_C2P_MASTERCARD_IS_PROCESS_ACTIVE', true)
        commit('SET_C2P_MASTERCARD_PROFILES', click2PayMastercardClass.profiles)
        commit('SET_C2P_MASTERCARD_ID_TOKEN', idToken)
        commit('SET_C2P_MASTERCARD_SRC_CORRELATION_ID', click2PayMastercardClass.srcCorrelationId)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_C2P_MASTERCARD_IS_PROCESS_AVAILABLE', false)
        commit('SET_C2P_MASTERCARD_IS_PROCESS_ACTIVE', false)
        dispatch('logC2PTransaction', {
          context: 'C2P getSrcProfile',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_C2P_MASTERCARD_LOADING', false)
      })
  },
  getC2PMastercardIdentityLookup ({ commit, state, dispatch }, email) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PMastercardIdentityLookup', email)
    }
    commit('SET_C2P_MASTERCARD_LOADING', true)
    return click2PayMastercardClass.identityLookup(email)
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P identityLookup',
          message: 'C2P getC2PMastercardIdentityLookup success',
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        commit('SET_C2P_MASTERCARD_IS_RECOGNIZED_BY_EMAIL', click2PayMastercardClass.consumerPresent)
        if (click2PayMastercardClass.consumerPresent) {
          commit('SET_C2P_MASTERCARD_LOADING', false)
          commit('SET_C2P_MASTERCARD_IS_PROCESS_ACTIVE', true)
          commit('SET_C2P_MASTERCARD_LAST_USED_CARD_TIMESTAMP', click2PayMastercardClass.lastUsedCardTimestamp)
          return Promise.resolve()
        }
        commit('SET_C2P_MASTERCARD_IS_PROCESS_ACTIVE', false)
        commit('SET_C2P_MASTERCARD_LOADING', false)
        return Promise.reject(new Error('Email not registered.'))
      })
      .catch(error => {
        commit('SET_C2P_MASTERCARD_LOADING', false)
        commit('SET_C2P_MASTERCARD_IS_RECOGNIZED_BY_EMAIL', false)
        commit('SET_C2P_MASTERCARD_IS_PROCESS_ACTIVE', false)
        dispatch('logC2PTransaction', {
          context: 'C2P identityLookup',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        return Promise.reject(error)
      })
  },
  getC2PMastercardInitiateIdentityValidation ({ commit, dispatch }, validationChannel) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PMastercardInitiateIdentityValidation')
    }
    commit('SET_C2P_MASTERCARD_LOADING', true)
    return click2PayMastercardClass.initiateIdentityValidation(validationChannel)
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P initiateIdentityValidation',
          message: 'C2P getC2PMastercardInitiateIdentityValidation success',
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        commit('SET_C2P_MASTERCARD_IDENTITY_VALIDATION', click2PayMastercardClass.identityValidation)
        return Promise.resolve()
      })
      .catch(error => {
        dispatch('logC2PTransaction', {
          context: 'C2P initiateIdentityValidation',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_C2P_MASTERCARD_LOADING', false)
      })
  },
  getC2PMastercardCompleteIdentityValidation ({ commit, dispatch }, { validationData, rememberMe }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PMastercardCompleteIdentityValidation')
    }
    let params = {}
    if (rememberMe) {
      params.complianceResources = [
        {
          complianceType: 'REMEMBER_ME',
          uri: 'https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html',
          version: 'LATEST'
        }
      ]
    }
    return click2PayMastercardClass.completeIdentityValidation(validationData, params)
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P completeIdentityValidation',
          message: 'C2P getC2PMastercardCompleteIdentityValidation success',
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        commit('SET_C2P_MASTERCARD_ID_TOKEN', click2PayMastercardClass.idTokens[0])
        return dispatch('getC2PMastercardGetSrcProfile', click2PayMastercardClass.idTokens[0])
        // return Promise.resolve()
      })
      .catch(error => {
        dispatch('logC2PTransaction', {
          context: 'C2P completeIdentityValidation',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.mastercard
        })
        return Promise.reject(error)
      })
  },
  getC2PMastercardCheckout ({ commit, state, dispatch }, params) {
    commit('SET_C2P_MASTERCARD_PROCESSING', true)
    const data = {
      srcDigitalCardId: params.srcDigitalCardId,
      encryptedCard: params.encryptedCard,
      dpaTransactionOptions: {
        customInputData: {
          'com.mastercard.dcfExperience': 'WITHIN_CHECKOUT'
        },
        dpaLocale: params.locale,
        isGuestCheckout: false,
        confirmPayment: false,
        consumerNationalIdentifierRequested: false,
        dpaAcceptedBillingCountries: [],
        dpaAcceptedShippingCountries: [],
        consumerEmailAddressRequested: true,
        consumerNameRequested: true,
        consumerPhoneNumberRequested: true,
        dpaShippingPreference: 'NONE',
        dpaBillingPreference: 'NONE',
        paymentOptions: {
          dpaDynamicDataTtlMinutes: 15,
          dynamicDataType: 'CARD_APPLICATION_CRYPTOGRAM_SHORT_FORM'
        }
      },
      complianceSettings: {
        complianceResources: [
          {
            complianceType: 'TERMS_AND_CONDITIONS',
            uri: 'https://www.mastercard.com/global/click-to-pay/country-listing/terms.html',
            version: 'LATEST'
          },
          {
            complianceType: 'PRIVACY_POLICY',
            uri: 'https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html',
            version: 'LATEST'
          }
        ]
      },
      windowRef: document.querySelector('#click2payIframe').contentWindow
    }
    if (params.rememberMe) {
      data['complianceSettings']['complianceResources'].push({
        complianceType: 'REMEMBER_ME',
        uri: 'https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html',
        version: 'LATEST'
      })
    }
    if (state.idToken) {
      data.idToken = state.idToken
    } else {
      data.srciActionCode = 'NEW_USER'
      data.consumer = params.consumer
    }
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PMastercardCheckout', data)
    }
    return click2PayMastercardClass.checkout(data)
      .then(() => {
        dispatch('logC2PTransaction', { context: 'C2P checkout', message: 'C2P_CHECKOUT_SUCCESS', organization: CLICK2PAY_ORGANIZATION.mastercard })
        commit('SET_C2P_MASTERCARD_CHECKOUT_RESULT', click2PayMastercardClass.checkoutResult)
        return Promise.resolve()
      })
      .catch(error => {
        dispatch('logC2PTransaction', { context: 'C2P checkout', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error, isFatal: true, organization: CLICK2PAY_ORGANIZATION.mastercard })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_C2P_MASTERCARD_PROCESSING', false)
      })
  },
  getC2PMastercardUnbindAppInstance () {
    return click2PayMastercardClass.unbindAppInstance()
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  resetC2PMastercardData ({ commit }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('resetC2PMastercardData')
    }
    commit('SET_C2P_MASTERCARD_IS_PROCESS_AVAILABLE', true)
    commit('SET_C2P_MASTERCARD_IS_PROCESS_ACTIVE', false)
    commit('SET_C2P_MASTERCARD_IS_RECOGNIZED_BY_EMAIL', null)
    commit('SET_C2P_MASTERCARD_IDENTITY_VALIDATION', null)
    commit('SET_C2P_MASTERCARD_PROFILES', null)
    commit('SET_C2P_MASTERCARD_ID_TOKEN', null)
    commit('SET_C2P_MASTERCARD_SRC_CORRELATION_ID', '')
    commit('SET_C2P_MASTERCARD_LAST_USED_CARD_TIMESTAMP', null)
    commit('SET_C2P_MASTERCARD_CHECKOUT_RESULT', null)
    return click2PayMastercardClass.resetInstance()
  }
}

const getters = {
  isC2PMastercardSDKLoaded (state) {
    return state.isSdkLoaded
  },
  isC2PMastercardSdkInitFinished (state) {
    return state.isSdkInitFinished
  },
  isC2PMastercardRecognizedByCookie (state) {
    return state.isRecognizedByCookie
  },
  isC2PMastercardRecognizedByEmail (state) {
    return state.isRecognizedByEmail
  },
  isC2PMastercardProcessAvailable (state) {
    return state.isProcessAvailable
  },
  isC2PMastercardProcessActive (state) {
    return state.isProcessActive
  },
  getC2PMastercardIdentityValidation (state) {
    return state.identityValidation
  },
  getC2PMastercardIdToken (state) {
    return state.idToken
  },
  getC2PMastercardProfile (state) {
    if (!state.profiles || !state.profiles.length) {
      return false
    }
    return state.profiles[0]
  },
  getC2PMastercardCards (state, getters) {
    const userProfile = getters.getC2PMastercardProfile
    if (!userProfile) {
      return []
    }
    return userProfile.maskedCards
  },
  getC2PMastercardCardsIds (state, getters) {
    const result = []
    const cardList = getters.getC2PMastercardCards
    if (cardList && cardList.length) {
      cardList.forEach(card => {
        result.push(card.srcDigitalCardId)
      })
    }
    return result
  },
  getC2PMastercardLastUsedCardTimestamp (state) {
    return state.lastUsedCardTimestamp ? parseInt(state.lastUsedCardTimestamp, 10) : null
  },
  getC2PMastercardCheckoutResult (state) {
    return state.checkoutResult
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
