import { ToolsClass } from '@/base/class/tools.class'
import { CLICK2PAY_LOG_LEVEL, CLICK2PAY_ORGANIZATION } from '@/base/const/click2pay.const'
import { click2PayVisaClass } from '@/base/class/visa.click2pay.class'
import { LANGUAGES_CODE_LIST } from '@/base/const/lang.const'

const state = {
  loading: false,
  processing: false,
  isSdkLoaded: false,
  isSdkInitFinished: false,
  isProcessAvailable: false,
  isProcessActive: false,
  isRecognizedByCookie: null,
  isRecognizedByEmail: null,
  identityValidation: null,
  profiles: null,
  idToken: null,
  srcCorrelationId: '',
  lastUsedCardTimestamp: null,
  checkoutResult: null
}

const mutations = {
  SET_C2P_VISA_LOADING (state, value) {
    state.loading = value
  },
  SET_C2P_VISA_PROCESSING (state, value) {
    state.processing = value
  },
  SET_C2P_VISA_IS_SDK_LOADED (state, value) {
    state.isSdkLoaded = value
  },
  SET_C2P_VISA_IS_SDK_INIT_FINISHED (state, value) {
    state.isSdkInitFinished = value
  },
  SET_C2P_VISA_IS_PROCESS_AVAILABLE (state, value) {
    state.isProcessAvailable = value
  },
  SET_C2P_VISA_IS_PROCESS_ACTIVE (state, value) {
    state.isProcessActive = value
  },
  SET_C2P_VISA_IS_RECOGNIZED_BY_COOKIE (state, value) {
    state.isRecognizedByCookie = value
  },
  SET_C2P_VISA_IS_RECOGNIZED_BY_EMAIL (state, value) {
    state.isRecognizedByEmail = value
  },
  SET_C2P_VISA_IDENTITY_VALIDATION (state, data) {
    state.identityValidation = data
  },
  SET_C2P_VISA_PROFILES (state, data) {
    state.profiles = data
  },
  SET_C2P_VISA_ID_TOKEN (state, value) {
    state.idToken = value
  },
  SET_C2P_VISA_SRC_CORRELATION_ID (state, value) {
    state.srcCorrelationId = value
  },
  SET_C2P_VISA_LAST_USED_CARD_TIMESTAMP (state, value) {
    state.lastUsedCardTimestamp = value
  },
  SET_C2P_VISA_CHECKOUT_RESULT (state, value) {
    state.checkoutResult = value
  }
}

const actions = {
  loadC2PVisaScript ({ commit, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('loadC2PVisaScript')
    }
    return new Promise((resolve, reject) => {
      commit('SET_C2P_VISA_LOADING', true)
      const script = document.createElement('script')
      script.src = process.env.VUE_APP_VISA_SDK
      script.async = true
      script.addEventListener('load', () => {
        commit('SET_C2P_VISA_LOADING', false)
        commit('SET_C2P_VISA_IS_SDK_LOADED', true)
        dispatch('logC2PTransaction', {
          context: 'C2P Visa SDK load',
          message: 'C2P Visa SDK loaded',
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        if (ToolsClass.isDebugEnabled()) {
          console.log('C2P Visa SDK loaded')
        }
        resolve(void 0)
      })
      script.addEventListener('error', (error) => {
        commit('SET_C2P_VISA_LOADING', false)
        commit('SET_C2P_VISA_IS_SDK_LOADED', false)
        dispatch('logC2PTransaction', {
          context: 'C2P Visa SDK load',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          isFatal: true,
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        reject(error)
      })
      document.head.appendChild(script)
    })
  },
  getC2PVisaInit ({ commit, state, dispatch, rootState }, initData) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PVisaInit', initData)
    }
    commit('SET_C2P_VISA_LOADING', true)
    const srcInitiatorId = initData.visa.srcInitiatorId
    const srciDpaId = initData.visa.srciDpaId
    const transaction = rootState.payment.paymentData.transaction
    const language = rootState.lang.current
    const dpaPresentationName = transaction.receiverNameForFront.length > 60 ? transaction.receiverNameForFront.substring(0, 60) : transaction.receiverNameForFront
    const params = {
      initiatorId: srcInitiatorId,
      dpaId: srciDpaId,
      transactionId: initData.srciTransactionId,
      transactionAmount: transaction.payAmount,
      transactionCurrencyCode: transaction.payAmountCurrency,
      locale: LANGUAGES_CODE_LIST[language],
      dpaPresentationName: dpaPresentationName,
      dpaName: dpaPresentationName
    }
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PVisaInit params', params)
    }

    const abortController = new AbortController()
    const abortSignal = abortController.signal
    let timeout = null

    timeout = setTimeout(() => {
      abortController.abort()
    }, 10000)

    return click2PayVisaClass.init(params)
      .then(response => {
        commit('SET_C2P_VISA_IS_SDK_INIT_FINISHED', true)
        dispatch('logC2PTransaction', {
          context: 'C2P Visa init',
          message: 'C2P getC2PVisaInit success',
          organization: CLICK2PAY_ORGANIZATION.visa
        })

        if (abortSignal.aborted) {
          console.log('getC2PVisaInit aborted!')
          commit('SET_C2P_VISA_LOADING', false)
          commit('SET_C2P_VISA_IS_SDK_INIT_FINISHED', false)
          return Promise.resolve()
        }
        clearTimeout(timeout)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_C2P_VISA_LOADING', false)
        commit('SET_C2P_VISA_IS_SDK_INIT_FINISHED', false)
        dispatch('logC2PTransaction', {
          context: 'C2P Visa init',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          isFatal: true,
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        return Promise.resolve()
      })
  },
  getC2PVisaIsRecognized ({ commit, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PVisaIsRecognized')
    }
    commit('SET_C2P_VISA_LOADING', true)
    return click2PayVisaClass.isRecognized()
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P isRecognized',
          message: 'C2P getC2PVisaIsRecognized success',
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        commit('SET_C2P_VISA_IS_RECOGNIZED_BY_COOKIE', click2PayVisaClass.recognized)
        commit('SET_C2P_VISA_IS_PROCESS_AVAILABLE', true)
        if (click2PayVisaClass.recognized) {
          commit('SET_C2P_VISA_IS_RECOGNIZED_BY_EMAIL', null)
          commit('SET_C2P_VISA_ID_TOKEN', click2PayVisaClass.idTokens[0])
          return dispatch('getC2PVisaGetSrcProfile', click2PayVisaClass.idTokens[0])
        } else {
          commit('SET_C2P_VISA_LOADING', false)
        }
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_C2P_VISA_LOADING', false)
        commit('SET_C2P_VISA_IS_PROCESS_AVAILABLE', false)
        dispatch('logC2PTransaction', {
          context: 'C2P isRecognized',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        return Promise.reject(error)
      })
  },
  getC2PVisaGetSrcProfile ({ commit, dispatch }, idToken) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PVisaGetSrcProfile')
    }
    commit('SET_C2P_VISA_LOADING', true)
    return click2PayVisaClass.getSrcProfile(idToken)
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P getSrcProfile',
          message: 'C2P getC2PVisaGetSrcProfile success',
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        commit('SET_C2P_VISA_IS_PROCESS_ACTIVE', true)
        commit('SET_C2P_VISA_PROFILES', click2PayVisaClass.profiles)
        commit('SET_C2P_VISA_ID_TOKEN', idToken)
        commit('SET_C2P_VISA_SRC_CORRELATION_ID', click2PayVisaClass.srcCorrelationId)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_C2P_VISA_IS_PROCESS_AVAILABLE', false)
        commit('SET_C2P_VISA_IS_PROCESS_ACTIVE', false)
        dispatch('logC2PTransaction', {
          context: 'C2P getSrcProfile',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_C2P_VISA_LOADING', false)
      })
  },
  getC2PVisaIdentityLookup ({ commit, state, dispatch }, email) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PVisaIdentityLookup', email)
    }
    commit('SET_C2P_VISA_LOADING', true)
    return click2PayVisaClass.identityLookup(email)
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P identityLookup',
          message: 'C2P getC2PVisaIdentityLookup success',
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        commit('SET_C2P_VISA_IS_RECOGNIZED_BY_EMAIL', click2PayVisaClass.consumerPresent)
        if (click2PayVisaClass.consumerPresent) {
          commit('SET_C2P_VISA_LOADING', false)
          commit('SET_C2P_VISA_IS_PROCESS_ACTIVE', true)
          commit('SET_C2P_VISA_LAST_USED_CARD_TIMESTAMP', click2PayVisaClass.lastUsedCardTimestamp)
          return Promise.resolve()
        }
        commit('SET_C2P_VISA_IS_PROCESS_ACTIVE', false)
        commit('SET_C2P_VISA_LOADING', false)
        return Promise.reject(new Error('Email not registered.'))
      })
      .catch(error => {
        commit('SET_C2P_VISA_LOADING', false)
        commit('SET_C2P_VISA_IS_RECOGNIZED_BY_EMAIL', false)
        commit('SET_C2P_VISA_IS_PROCESS_ACTIVE', false)
        dispatch('logC2PTransaction', {
          context: 'C2P identityLookup',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        return Promise.reject(error)
      })
  },
  getC2PVisaInitiateIdentityValidation ({ commit, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PVisaInitiateIdentityValidation')
    }
    commit('SET_C2P_VISA_LOADING', true)
    return click2PayVisaClass.initiateIdentityValidation()
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P initiateIdentityValidation',
          message: 'C2P getC2PVisaInitiateIdentityValidation success',
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        commit('SET_C2P_VISA_IDENTITY_VALIDATION', click2PayVisaClass.identityValidation)
        return Promise.resolve()
      })
      .catch(error => {
        dispatch('logC2PTransaction', {
          context: 'C2P initiateIdentityValidation',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_C2P_VISA_LOADING', false)
      })
  },
  getC2PVisaCompleteIdentityValidation ({ commit, dispatch }, validationData) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PVisaCompleteIdentityValidation')
    }
    return click2PayVisaClass.completeIdentityValidation(validationData)
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P completeIdentityValidation',
          message: 'C2P getC2PVisaCompleteIdentityValidation success',
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        commit('SET_C2P_VISA_ID_TOKEN', click2PayVisaClass.idTokens[0])
        return dispatch('getC2PVisaGetSrcProfile', click2PayVisaClass.idTokens[0])
        // return Promise.resolve()
      })
      .catch(error => {
        dispatch('logC2PTransaction', {
          context: 'C2P completeIdentityValidation',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        return Promise.reject(error)
      })
  },
  getC2PVisaCheckout ({ commit, state, dispatch, rootState }, params) {
    commit('SET_C2P_VISA_PROCESSING', true)
    const initData = rootState.c2p.initData
    const data = {
      srcCorrelationId: state.srcCorrelationId,
      srciTransactionId: initData.srciTransactionId,
      srcDigitalCardId: '',
      encryptedCard: '',
      idToken: '',
      windowRef: document.querySelector('#click2payIframe').contentWindow,
      dpaTransactionOptions: {
        dpaLocale: params.locale,
        dpaAcceptedBillingCountries: [],
        dpaAcceptedShippingCountries: [],
        dpaBillingPreference: 'NONE',
        dpaShippingPreference: 'FULL',
        consumerNameRequested: true,
        consumerEmailAddressRequested: true,
        consumerPhoneNumberRequested: true,
        paymentOptions: {
          dynamicDataType: 'CARD_APPLICATION_CRYPTOGRAM_LONG_FORM',
          dpaPanRequested: false
        },
        reviewAction: 'continue',
        checkoutDescription: 'Sample checkout',
        transactionType: 'PURCHASE',
        orderType: 'REAUTHORIZATION',
        payloadTypeIndicator: 'FULL'
      },
      complianceSettings: {
        complianceResources: [
          {
            complianceType: 'TERMS_AND_CONDITIONS',
            uri: 'usa.visa.com/legal/checkout/terms-of-service.html'
          },
          {
            complianceType: 'PRIVACY_POLICY',
            uri: 'usa.visa.com/legal/global-privacy-notice.html'
          }
        ]
      },
      srciActionCode: ''
    }

    // jesli platnosc zapamietana karta
    if (params.srcDigitalCardId) {
      data.srcDigitalCardId = params.srcDigitalCardId
    }

    // jesli dodajemy nowa karte
    if (params.encryptedCard) {
      data.encryptedCard = params.encryptedCard
      data.consumer = params.consumer
      data.consumer.consumerIdentity = {
        identityProvider: 'SRC',
        identityType: 'EMAIL_ADDRESS',
        identityValue: params.consumer.emailAddress
      }
      data.consumer.languageCode = 'PL'
      data.consumer.fullName = params.consumer.firstName + ' ' + params.consumer.lastName
    }

    // jeśli user jest zalogowany
    if (state.idToken) {
      data.idToken = state.idToken
    }

    // jeśli rejestrujemy nowego usera
    if (!state.idToken) {
      data.srciActionCode = 'NEW_USER'
      data.consumer = params.consumer
    }

    // jeśli operator ma zapamiętać urządzenie
    if (params.rememberMe) {
      data['complianceSettings']['complianceResources'].push({
        complianceType: 'REMEMBER_ME',
        uri: 'visa.checkout.com/privacy'
      })
    }

    if (ToolsClass.isDebugEnabled()) {
      console.log('getC2PVisaCheckout', data)
    }
    return click2PayVisaClass.checkout(data)
      .then(() => {
        dispatch('logC2PTransaction', {
          context: 'C2P checkout',
          message: 'C2P_CHECKOUT_SUCCESS',
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        commit('SET_C2P_VISA_CHECKOUT_RESULT', click2PayVisaClass.checkoutResult)
        return Promise.resolve()
      })
      .catch(error => {
        dispatch('logC2PTransaction', {
          context: 'C2P checkout',
          message: error,
          logLevel: CLICK2PAY_LOG_LEVEL.error,
          isFatal: true,
          organization: CLICK2PAY_ORGANIZATION.visa
        })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_C2P_VISA_PROCESSING', false)
      })
  },
  getC2PVisaUnbindAppInstance () {
    return click2PayVisaClass.unbindAppInstance()
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  resetC2PVisaData ({ commit }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('resetC2PVisaData')
    }
    commit('SET_C2P_VISA_IS_PROCESS_AVAILABLE', true)
    commit('SET_C2P_VISA_IS_PROCESS_ACTIVE', false)
    commit('SET_C2P_VISA_IDENTITY_VALIDATION', null)
    commit('SET_C2P_VISA_PROFILES', null)
    commit('SET_C2P_VISA_ID_TOKEN', null)
    commit('SET_C2P_VISA_SRC_CORRELATION_ID', '')
    commit('SET_C2P_VISA_LAST_USED_CARD_TIMESTAMP', null)
    commit('SET_C2P_VISA_CHECKOUT_RESULT', null)
    return click2PayVisaClass.resetInstance()
  }
}

const getters = {
  isC2PVisaSDKLoaded (state) {
    return state.isSdkLoaded
  },
  isC2PVisaSdkInitFinished (state) {
    return state.isSdkInitFinished
  },
  isC2PVisaRecognizedByCookie (state) {
    return state.isRecognizedByCookie
  },
  isC2PVisaRecognizedByEmail (state) {
    return state.isRecognizedByEmail
  },
  isC2PVisaProcessAvailable (state) {
    return state.isProcessAvailable
  },
  isC2PVisaProcessActive (state) {
    return state.isProcessActive
  },
  getC2PVisaIdentityValidation (state) {
    return state.identityValidation
  },
  getC2PVisaIdToken (state) {
    return state.idToken
  },
  getC2PVisaProfile (state) {
    if (!state.profiles || !state.profiles.length) {
      return false
    }
    return state.profiles[0]
  },
  getC2PVisaCards (state, getters) {
    const userProfile = getters.getC2PVisaProfile
    if (!userProfile) {
      return []
    }
    return userProfile.maskedCards
  },
  getC2PVisaCardsIds (state, getters) {
    const result = []
    const cardList = getters.getC2PVisaCards
    if (cardList && cardList.length) {
      cardList.forEach(card => {
        result.push(card.srcDigitalCardId)
      })
    }
    return result
  },
  getC2PVisaLastUsedCardTimestamp (state) {
    return state.lastUsedCardTimestamp ? parseInt(state.lastUsedCardTimestamp, 10) : null
  },
  getC2PVisaCheckoutResult (state) {
    return state.checkoutResult
  },
  getC2PVisaCorrelationId (state) {
    return state.srcCorrelationId
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
